<template>
  <div>
    <div class="AboutBanner">
      <img src="@/assets/img/about/banner-about.jpg" />
    </div>
    <!-- 公司介绍 -->
    <div class="AboutIntroduction">
      <!-- <div class="AboutIntroduction_title">
        <div>company information</div>
        <div>
          <div>公司资讯</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div> -->
      <div class="AboutIntroduction_content">
        <div>
          <div>
            <div class="companyName">广州微林软件有限公司</div>
            <div class="companyEnglishName">
              <span>Guangzhou VLimSoft Co., Ltd.</span>
              <div class="companyEnglishName_line"></div>
            </div>
          </div>
          <div class="companyInt">
            广州微林软件有限公司成立于2016年，位于广州市科学城基地的生产力促进中心。微林软件是一家专注于视觉检测和视觉工业自动化的高新企业，通过CV、DL等各种技术，努力打造视觉引导工业自动化的人工智能，极大促进生产力的发展。<br />
            微林软件高度重视自主创新，自公司成立以来，先后研发了具有自主知识产权的三维立体测距、手掌检测、二维码快速识别、视觉高精度测量（尺寸测量、计数、缺陷检测）等相关核心技术。并成功开发了具有自主软件著作权的服务端设备监控、平台化设备监控、机械臂控制、工业设备视觉检测、设备监控等系统检测软件。为客户研制出的高精度视觉对位系统、以及高可靠性的自动化检测设备及整体解决方案，同时可供应配套的工业相机、工业镜头、视觉光源及专业图像处置软件等标准产品。还为客户提供项目评价、计划设计、设备调试、技能咨询及训练等一体化的专业服务。<br />
            广州微林软件有限公司正处于高速发展时期，我们除了拥有领先水平的机器视觉商品之外，还能够获得提供的全方位的服务与支持，然后进一步为客户为客户提供全方位的协助。如果是接受过技能训练的直销人员，不仅仅只能处置疑难的的实践使用问题，还能随时处理与我们商品有关的任何技能疑问。公司全体员工将秉承＂炼就火眼金睛，成就智能机器＂的使命，朝着＂2025年，成为视觉领域的知名企业＂这一伟大愿景而迈进！
          </div>
        </div>
        <div>
          <img src="@/assets/img/about/pic-company.png" />
        </div>
      </div>
    </div>
    <!-- 经营战略 -->
    <div class="AboutOperating">
      <div><img src="@/assets/img/about/bg-company-zl.jpg" /></div>
      <div class="AboutOperating_info">
        <div>经营战略</div>
        <div>
          在视觉识别领域及人工智能领域成为不可或缺的行业先锋，为促进人们的生活智能化而努力。我们立足于自主创新，并通过不断完善，研发出拥有自主知识产权的核心技术，根据客户特点，提出完整的解决方案。公司坚持以质量求生存、客户至上的方针，建立一个高效的研发生产及售后服务体系，全面解决客户的难题，为企业提供降低劳动力成本、提高品质及效率的完善方案；为用户提供最匹配的家居管理。
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="AboutCourse">
      <!-- <div class="AboutIntroduction_title">
        <div>development history</div>
        <div>
          <div>发展历程</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/img/about/pic-fzlc.png" />
      </div> -->
      <div>
        <div class="newTitle">
          <div>发展历程</div>
          <div>development history</div>
          <div></div>
        </div>
        <div class="historyRecord">
          <!-- 日期侧边栏 -->
          <div class="dateRecord">
            <div
              v-for="(item, index) in historyRecord"
              :key="index"
              @click="handleCurrentChange(index, item.list)"
            >
              <div :class="selectCurrent == index ? 'dateRecord_active' : ''">
                {{ item.time }}
              </div>
              <div
                :class="
                  selectCurrent == index
                    ? 'dateRecord_dianActive'
                    : 'dateRecord_dian'
                "
              ></div>
            </div>
          </div>
          <!-- 历程内容 -->
          <div class="historyContent">
            <transition-group name="slide-fade">
              <div
                class="historyContent_item"
                v-for="item in courseContent"
                :key="item"
                v-show="!!courseContent"
              >
                {{ item }}
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <!-- 团队荣誉 -->
    <div class="AboutTeam">
      <div class="AboutIntroduction_title">
        <div>team honor</div>
        <div>
          <div>团队荣誉</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="AboutTeamBox">
        <div class="certificateBox">
          <el-image
            class="certificateBoxImg"
            v-for="(item, index) in certificate"
            :key="index"
            lazy
            :preview-src-list="[item.imgUrl]"
            :src="item.imgUrl"
          >
          </el-image>
        </div>
        <div class="patent">
          <div class="AboutTeam_title">
            <div>专利</div>
            <div>patents</div>
          </div>
          <div class="patentBox">
            <div class="patentBox_left">
              <el-image
                class="patentBox_leftImg"
                v-for="(item, index) in patent.slice(0, 3)"
                :src="item.imgUrl"
                :key="index"
                lazy
                :preview-src-list="[item.imgUrl]"
              >
              </el-image>
            </div>
            <div class="patentBox_right">
              <el-image
                class="patentBox_rightImg"
                v-for="(item, index) in patent.slice(3, 7)"
                :src="item.imgUrl"
                :key="index"
                lazy
                :preview-src-list="[item.imgUrl]"
              >
              </el-image>
            </div>
          </div>
        </div>
        <div>
          <div class="AboutTeam_title">
            <div>软件著作权</div>
            <div>Software copyright</div>
          </div>
          <div class="softwareCopyrightBox">
            <div v-for="(item, index) in previewList" :key="index">
              <el-image
                class="softwareCopyrightBoxImg"
                :preview-src-list="previewList"
                lazy
                :src="item"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="AboutIntroduction_switch">
        <div @click="prev()">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="AboutIntroduction_img" ref="sortMenu">
          <div
            v-for="(item, index) in honor"
            :key="index"
            class="AboutIntroduction_item"
          >
            <img :src="item.imgUrl" />
            <div class="AboutIntroduction_imgTitle">
              <div>{{ item.title }}</div>
              <div>{{ item.tips }}</div>
            </div>
          </div>
        </div>
        <div @click="next()">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 荣誉证书
      honor: [
        {
          imgUrl: require("@/assets/img/about/certificate-14.jpg"),
          title: "专利证书",
          tips: "一种自动递名片机器人",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-15a.jpg"),
          title: "软著证书",
          tips: "微林视觉软件V1.0",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-16a.jpg"),
          title: "软著证书",
          tips: "微林条形码动态识别软件",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-1.jpg"),
          title: "贯标证书",
          tips: "知识产权管理体系认证证书",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-2.jpg"),
          title: "软著证书",
          tips: "微林PC端设备监控软件V1.0",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-3.jpg"),
          title: "软著证书",
          tips: "微林事件中心软件V1.0",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-4.jpg"),
          title: "软著证书",
          tips: "微林通讯中心软件V1.0",
        },
        {
          imgUrl: require("@/assets/img/about/certificate-5.jpg"),
          title: "软著证书",
          tips: "微林监控硬盘软件V1.0",
        },
      ],
      // 滚动条距离
      sortMenu: null,
      // 下一页
      nextPage: 0,
      // 上一页
      prevPage: 0,
      // 历史记录
      historyRecord: [
        {
          time: "2021",
          list: [
            "2021年08月冰眸小程序完成升级，APP在各大应用市场上架",
            "2021年04月冰眸二期改版完成，项目整体规划完成",
          ],
        },
        {
          time: "2020",
          list: [
            "2020年10月冰眸项目核心算法模型完成，申请6项专利",
            "2020年04月视觉检测产品被认定为广东省高新科技产品",
          ],
        },
        {
          time: "2019",
          list: [
            "2019年12月获认证为广东省第一批国家高新科技企业",
            "2019年05月完成视觉检测项目，机械臂引导控制的研发",
          ],
        },
        {
          time: "2018",
          list: ["2018年02月获得国家专利，知识产权管理体系认证"],
        },
        {
          time: "2017",
          list: [
            "2017年01月广州微林软件有限公司正式挂牌成立，开始涉足视觉检测行业",
          ],
        },
      ],
      // 选中当前
      selectCurrent: 0,
      // 历程内容
      courseContent: [],

      //荣誉证书
      certificate: [
        {
          imgUrl: require("@/assets/img/honor/High-tech Enterprise Certificate.png"),
        },
        { imgUrl: require("@/assets/img/honor/Software certificate.jpg") },
      ],
      // 证书
      patent: [
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/certificate-1.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/certificate-14.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Vision-based guidance system.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/About the identification method of grabbed items.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Bin food identification method.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Hand-held article tracking behavior determination method.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/patentCertificate/Invention patent application.png"),
        },
      ],
      // 软件著作权
      softwareCopyright: [
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Bingmo Refrigerator Food Management Software2.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Barcode Dynamic Recognition Software2.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Bingmo goods visual operation management software V1.0.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Bingmo Shop.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Bingmoo Mini Program.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Micro-Lin carpet cutting line visual detection and positioning software V1.0.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Relay zero-crossing on-off control and setting software.png"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin binocular vision software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Communication Center Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Event Center Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Industrial Equipment Visual Inspection Software V1.0.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Monitoring Hard Disk Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Palm Recognition Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin PC terminal equipment monitoring software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin platform equipment monitoring software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin QR code quick recognition software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin robotic arm control software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin server equipment monitoring software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Car Light Inspection.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Cotton Mask Detection Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision J-type metal detection software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Metal Spring Inspection Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision PCB Board Recognition and Positioning Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Software-USB Inspection.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Software-Volume Dynamic Recognition.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin Vision Vehicle Workpiece Inspection Software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin visual capacitance positive and negative detection software.jpg"),
        },
        {
          imgUrl: require("@/assets/img/honor/SoftwareWorks/Weilin WeChat terminal equipment monitoring software.jpg"),
        },
      ],
      // 预览图
      previewList: [],
    };
  },
  created() {
    this.courseContent = this.historyRecord[0].list;
    this.softwareCopyright.map((item) => {
      this.previewList.push(item.imgUrl);
    })
  },
  watch: {
    nextPage() {
      this.$refs.sortMenu.scrollLeft = (1460 / 4) * this.nextPage;
      this.sortMenu = this.$refs.sortMenu.scrollLeft;
    },
    prevPage() {
      this.$refs.sortMenu.scrollLeft =
        this.sortMenu - this.prevPage * (1460 / 4);
      this.nextPage -= 1;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.sortMenu = this.$refs.sortMenu.scrollLeft;
    },
    // 上一页
    prev() {
      if (this.sortMenu == 0) {
        return;
      }
      this.prevPage += 1;
    },
    // 下一页
    next() {
      if (this.sortMenu == 1460) {
        return;
      }
      this.nextPage += 1;
    },
    // 处理当前日期选中
    handleCurrentChange(index, item) {
      this.selectCurrent = index;
      this.courseContent = [];
      this.$nextTick(() => {
        this.courseContent = item;
      });
    },
  },
};
</script>

<style scope>
/* 关于我们首图 */
.AboutBanner img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: flex;
}
/* 公司介绍 */
.AboutIntroduction {
  width: 1440px;
  margin: 100px auto;
}
.AboutIntroduction_title {
  position: relative;
}
.AboutIntroduction_title > div:nth-of-type(1) {
  font-size: 40px;
  color: #e5e5e5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AboutIntroduction_title > div:nth-of-type(2) {
  font-size: 30px;
  color: #002780;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.columnTitle_bg {
  position: relative;
  margin-top: 10px;
}
.columnTitle_bg > div:nth-of-type(1) {
  position: absolute;
  width: 60px;
  height: 5px;
  background-color: #000180;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.columnTitle_bg > div:nth-of-type(2) {
  width: 200px;
  height: 1px;
  background-color: #000;
}
/* 内容 */
.AboutIntroduction_content {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.AboutIntroduction_content > div:nth-of-type(1) {
  margin-right: 75px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}
.AboutIntroduction_content img {
  width: 610px;
  height: 558px;
  display: flex;
  object-fit: cover;
}
.companyName {
  font-size: 32px;
  color: #333333;
}
.companyEnglishName {
  margin-top: 12px;
  font-size: 20px;
  color: #333333;
  padding: 5px 10px;
  width: 50%;
  border: 1px solid #002780;
  position: relative;
}
.companyEnglishName_line {
  position: absolute;
  right: calc(-130% + 40px);
  height: 1px;
  width: 130%;
  background-color: #002780;
  top: 50%;
  transform: translateY(-50%);
}
.companyInt {
  font-size: 18px;
  color: #999999;
  margin-top: 60px;
}
/* 经营战略 */
.AboutOperating {
  position: relative;
}
.AboutOperating img {
  width: 100%;
  height: 420px;
  display: flex;
  object-fit: cover;
}
.AboutOperating_info {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateY(-30%);
}
.AboutOperating_info > div:nth-of-type(1) {
  font-size: 32px;
  font-weight: 500;
  color: #002780;
  line-height: 90px;
}
.AboutOperating_info > div:nth-of-type(2) {
  width: 740px;
  height: 135px;
  font-size: 18px;
  font-weight: 400;
  color: #002780;
  line-height: 28px;
  box-sizing: border-box;
}
/* 发展历程 */
.AboutCourse {
  width: 1440px;
  margin: 120px auto 160px;
}
/* .AboutCourse img {
  height: 350px;
  width: 100%;
  display: flex;
  object-fit: cover;
  margin: 100px 0;
} */
/* 团队荣誉 */
.AboutTeam {
  padding: 120px 0;
  background-color: #f5f5f5;
}
.AboutIntroduction_switch {
  margin: 62px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AboutIntroduction_switch i {
  font-size: 58px;
  color: #999;
  font-weight: 600;
  cursor: pointer;
  transform: translateY(-58px);
}
.AboutIntroduction_switch i:hover {
  color: #000080;
}
.AboutIntroduction_img {
  width: 1440px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow-x: scroll;
}
.AboutIntroduction_img::-webkit-scrollbar {
  display: none;
}
.AboutIntroduction_item img {
  width: calc(1440px / 4);
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  object-fit: cover;
}
.AboutIntroduction_imgTitle {
  margin-top: 22px;
}
.AboutIntroduction_imgTitle > div:nth-of-type(1) {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  text-align: center;
}
.AboutIntroduction_imgTitle > div:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 10px;
}
/* 新标题 */
.newTitle {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.newTitle > div:nth-of-type(1) {
  font-size: 40px;
  color: #333333;
}
.newTitle > div:nth-of-type(2) {
  font-size: 20px;
  color: #333333;
  text-transform: uppercase;
}
.newTitle > div:nth-of-type(3) {
  width: 380px;
  margin-top: 14px;
  border-bottom: 1px solid #002780;
}
/* 发展历程内容 */
.historyRecord {
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 120px;
  align-items: center;
  justify-content: space-between;
}
.dateRecord {
  width: 25%;
  height: 100%;
  color: #ccc;
  display: flex;
  font-size: 30px;
  transition: all 0.5s;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #ccc;
}
.dateRecord > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 6px 0;
  cursor: pointer;
}
.dateRecord_dian {
  right: -8px;
  width: 16px;
  height: 16px;
  margin-left: 20px;
  position: relative;
  transition: all 0.5s;
  border-radius: 100%;
  background-color: #cccccc;
}
.dateRecord_active {
  font-size: 60px;
  font-weight: 500;
  color: transparent;
  background: linear-gradient(to right, #004eff, #002780);
  background-clip: text;
}
.dateRecord_dianActive {
  width: 24px;
  height: 24px;
  right: -12px;
  margin-left: 20px;
  position: relative;
  border-radius: 100%;
  transition: all 0.5s;
  background: linear-gradient(to right, #004eff 0%, #002780 100%);
}

/* 历程内容 */
.historyContent {
  width: 65%;
  height: 100%;
}
.historyContent_item {
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 45px;
  margin-top: 60px;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(20px);
  opacity: 0;
}

/* 团队荣誉 */
.AboutTeamBox {
  width: 1440px;
  margin: 100px auto 0;
}
.certificateBox {
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.certificateBoxImg {
  width: calc(50% - 50px);
  display: flex;
}
.AboutTeam_title {
  width: 30%;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #333;
  margin-bottom: 60px;
}
.AboutTeam_title > div:nth-of-type(1) {
  color: #333;
  font-size: 30px;
  font-weight: 500;
  margin-right: 12px;
}
.AboutTeam_title > div:nth-of-type(2) {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.patentBox {
  width: 100%;
  margin-bottom: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.patentBox_left {
  width: 100%;
  height: 388px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.patentBox_leftImg {
  display: flex;
  height: 100%;
}
.patentBox_right {
  width: 62%;
  height: 388px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.patentBox_rightImg {
  display: flex;
  height: 100%;
}
.patentBox_rightImg:nth-of-type(1) {
  position: absolute;
  left: 50px;
}
.patentBox_rightImg:nth-of-type(2) {
  position: absolute;
  left: 124px;
}
.patentBox_rightImg:nth-of-type(3) {
  position: absolute;
  left: 198px;
}
.patentBox_rightImg:nth-of-type(4) {
  position: absolute;
  left: 272px;
}

.softwareCopyrightBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 836px;
  flex-wrap: wrap;
}
.softwareCopyrightBox > div {
  width: calc(100% / 15);
}
.softwareCopyrightBoxImg {
  width: 280px;
  height: 388px;
}
</style>